import React from 'react';
import PropTypes from 'prop-types';
import { getScores, getDiscipline } from '../../lib/brain';
import useLanguageStrings from '../../hooks/useLanguageStrings';

function Debug({ disciplineName: disciplineNameCurrent }) {
  const { discipline, summary, restart } = useLanguageStrings();
  const scores = getScores();
  const disciplineStatus = getDiscipline();
  return (
    <div>
      <hr />
      {disciplineNameCurrent !== null && (
        <p>
          {discipline}
          :
          &nbsp;
          {disciplineNameCurrent}
        </p>
      )}
      {scores.length > 0 && (
        <h3>{summary}</h3>
      )}
      {scores.map(({ disciplineId, disciplineName, score }) => (
        <p key={disciplineId}>
          {disciplineName}
          =
          {score}
        </p>
      ))}
      {scores.length > 0 && (
        <>
          <h3>Result</h3>
          {disciplineStatus.disciplineName}
        </>
      )}
      <hr />
      <p>
        <a href="/debug/">{restart}</a>
      </p>
    </div>
  );
}

Debug.defaultProps = {
  disciplineName: null,
};

Debug.propTypes = {
  disciplineName: PropTypes.string,
};

export default Debug;

import React from 'react';
import Debug from '../components/debug/debug';
import SEO from '../components/seo';

export default function Summary() {
  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    },
  ];
  return (
    <>
      <SEO title="Debug Summary" meta={meta} />
      <Debug />
    </>
  );
}

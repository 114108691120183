const disciplines = new Map();
const scores = new Map();

function getDisciplineScore(disciplineId) {
  const score = scores.get(disciplineId);
  return score === undefined ? 0 : score;
}

function onAnswer(q, value) {
  const { disciplineId, disciplineName } = q;
  disciplines.set(disciplineId, disciplineName);
  const score = getDisciplineScore(disciplineId);
  scores.set(disciplineId, score + value);
}

function getScores() {
  const result = [];
  scores.forEach((value, key) => {
    result.push({
      disciplineId: key,
      disciplineName: disciplines.get(key),
      score: value,
    });
  });
  return result;
}

function getDiscipline() {
  const discipline = {
    disciplineName: null,
    score: 0,
  };
  scores.forEach((score, key) => {
    if (score > discipline.score) {
      discipline.score = score;
      discipline.disciplineName = disciplines.get(key);
    }
  });
  return discipline;
}

export { onAnswer, getScores, getDiscipline };
